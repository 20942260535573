import header from "./images/header.jpg";
import s1 from "./images/servicii/s1.jpg";
import s2 from "./images/servicii/s2.jpg";
import s3 from "./images/servicii/s3.jpg";
import s4 from "./images/servicii/s4.jpg";
import s5 from "./images/servicii/s5.jpg";
import s6 from "./images/servicii/o7.jpg";
import img_economie from "./images/reducere.jpg";
import img_comfort from "./images/reducere.jpg";
import logou from "./images/logo1.png";
import logou1 from "./images/logo2.png";


const data = {
    introData: [{
        logo: logou,
        logo1: logou1,
        name: "EcoizolareMD",
        descript: "Soluții și Servicii Complexe de Izolare cu Fulgi de Celuloză",
        deviz: "Vrei să te ajutăm să economisești la facturi cu 35% la încălzire după ce iți izolăm podul sau mansarda?",
        title: "Izolare Termica",
        pasiune: "PASIUNE PENTRU CALITATE",
        imgdesktop: header,
        imgmobil: header,
        data: "8 Octombrie 2022",
        data_confirmare: "",
        savedata: "Vă invit!",
        ani: "De 14 Ani ÎMPREUNĂ!",
        email: " @Izolaretermika", 
        mailto: 'https://www.facebook.com/Izolaretermika',
       tel: "+373 767 67 111",
       phone: "tel:+37376767111",
       viber: "viber://chat?number=%2B37376767111",
       whatsapp: "https://wa.me/+37376767111",
       messenger: "https://www.messenger.com/t/Izolaretermika",
       tel1: "",
       phone1: "",
       viber1: "",
       whatsapp1: "",
       messenger1: "",
    }],
   
    cardService: [
        {
            id: 1,
            img: s1,
            title: "Izolarea Acoperișului",
            name: "Club Kids Planets",
            detalii: "ddff",
        },
        {
            id: 2,
            img: s2,
            title: "Izolarea Mansardei",
            name: "Club Kids Planets",
        },
        {
            id: 3,
            img: s4,
            title: "Izolarea Podului necirculabil",
            name: "Club Kids Planets",
        },
        {
            id: 4,
            img: s3,
            title: "Izolarea pereților/tavanelor",
            name: "Club Kids Planets",
        },
        {
            id: 5,
            img: s6,
            title: "Izolarea spațiilor greu accesibile",
            name: "Club Kids Planets",
        },
        {
            id: 6,
            img: s5,
            title: "Inspectarea cu termovizor",
            name: "Club Kids Planets",
        },

    ],
  
    blogDataNasii: [
        {
            id: 1,
            img: img_economie,
            title1: "Vrei să reduci costurile la facturi?",
            title2: "Facturi mai mici la energie!",
            message: "Izolația STEICO reduce costurile consumului de energie și de încălzire în timpul iernii cu până la 35%,  ceea ce înseamnă facturi mai mici și impact redus al locuinței asupra mediului.",
        },
        {
            id: 2,
            img: img_comfort,
            title1: "Vrei să reduci costurile la facturi?",
            title2: "Mai mult confort",
            message: "Aceeași izolație protejează împotriva încălzirii în timpul verii. Totodată contribuie în mare măsură la îmbunătățirea calității aerului din locuință, prin asigurarea unui microclimat sănătos.",
        },
    ],
   
   
   

}

export default data;