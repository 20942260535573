
   import * as React from 'react';
   import List from '@mui/material/List';
   import ListItem from '@mui/material/ListItem';
   import ListItemText from '@mui/material/ListItemText';
   import ListItemAvatar from '@mui/material/ListItemAvatar';
   import Avatar from '@mui/material/Avatar';
   import VerifiedIcon from '@mui/icons-material/Verified';
  
  const Motive = () => {
    let data =[
      {
        id: 1,
      motiv: 'Material certificat și GARANȚIE la serviciile noastre',
      },
      {
        id: 2,
      motiv: 'Experienta peste 14 ani',
      },
      {
        id: 3,
      motiv: 'Executăm comanda în termen',
      },
      {
        id: 4,
      motiv: 'Utilaj performant specializat',
      },
      {
        id: 5,
      motiv: 'Executarea serviciilor la cheie',
      },
      
    ]
    return (
      <>
 
<section id="motive" className="p-lg-5 container"  >
  <h2 className='text-center mt-5'>
  5 MOTIVE CA SĂ NE ALEGI PE NOI
  </h2>
<div className='container text-center'>
 {data.map((item, index) => {
                  return ( 
<List sx={{ width: '100%', maxWidth: 800, }}>
         <ListItem>
           <ListItemAvatar>
             <Avatar style={{backgroundColor: 'green'}}  >
             <VerifiedIcon />
             </Avatar>
           </ListItemAvatar>
           <ListItemText primary={item.motiv} style={{color: 'black'}}/>
         </ListItem>
       </List>
   ) })}
</div>
 

</section>


      </>
    );
  }
  
   export default Motive;