
import * as React from 'react';


const Pret = () => {

  return (
    <>

      <section id="pret" className="p-lg-5 container"  >

        <div className='container' style={{ maxWidth: '800px' }}>

          <h3 className='text-center'><b>Promoție</b></h3>
          <h4 style={{ color: 'black' }} className='text-center'>Cere oferta acum și beneficiază de reduceri plus o analiză amplă a casei cu termovizor - garatuit după izolare!</h4>
          <div className='text-center'>
            <a href='#forms' style={{ fontSize: '20px' }} className="btn m-5 text-center animate__animated animate__pulse animate__delay-4s animate__infinite">Cere Oferta</a>
          </div>


          <hr style={{ width: '50%', background: 'green', height: '7px', margin: 'auto' }} />

          <h2 className='text-center mt-5'>
            PREȚ
          </h2>
          <h5 style={{ color: 'black' }} className='p-4 text-center'>
            Cel mai avansat sistem de izolații este acum la îndemâna ta! <br /><br />
            Prețul unei izolări cu celuloză este individual pentru fiecare casă în parte și se calculează în depedență de mai mulți factori:
          </h5>

          <ol style={{ color: 'black', listStylePosition: 'inside' }}>
            <li>Suprafața de izolare (m<sup>2</sup>);</li>
            <li>
              Stratul de izolare (cm) recomandat + 10% coeficient de tasare;
            {/*  <ul>
                <li>10 cm - pereti</li>
                <li>15 cm -  mansarde</li>
                <li>20 cm - poduri necirculabile</li>
              </ul>*/ }
            </li>
            <li>
              Densitate (kg/m<sup>3</sup>): {/**  <br />de ex. pentru un strat de 20cm la:
              <ul>
                <li>pante - 50-55 kg/m<sup>3</sup></li>
                <li>planseu 20 kg/m<sup>3</sup></li>
                <li>tavan</li>
                <li>pereti</li>
              </ul>*/}
            </li>
            <li>Locația obiectului - transport (km);</li>
            <li>Conditiile căilor de acces pentru izolare;</li>
            <li>Lucrri de pregatire (obțional: ex. demontarea izolații vechi, etc...);</li>
            <li>Materiale și lucrări suplimentare (obtional: ex. montarea ....)</li>
          </ol>

         

        </div>


      </section>


    </>
  );
}

export default Pret;