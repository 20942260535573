import React from "react";
import { ParallaxProvider } from 'react-scroll-parallax';
import '../css/Timer.css';
import '../css/Body.css';
import Parallaximg from "../Parallaximg";
import FormSection from "../FormSection";
import Slide from "./Slide";
import data from "../Data";
import SlideGallery from "./SlideGallery";
import VideoAnimation from "./Video";
import CardService from "../cardService";
import Beneficii from "./Beneficii";
import FAQ from "./Faq";
import Motive from "./Motive";
import Pret from "./Pret";
import GalerieVideo from "./VideoYut";
import { padding } from "@mui/system";


const Home = (props) => {


    return (



        <ParallaxProvider>

            {data.introData.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 500 ? item.imgdesktop : item.imgmobil} height={window.innerWidth >= 700 ? '800px' : '1000px'} opacity=".5" >
                        <div  className="row text-center align-items-center m-2 m-md-5" style={{ position: 'absolute' }}>
                            <div className="col-lg-8 col-md-8 col-sm-12  mt-4 mb-4" style={{ maxWidth: '800px' }}>
                                <img className="animate__animated animate__bounceInLeft animate__delay-3s" id="logo" src={item.logo} width={window.innerWidth >= 500 ? '300px' : '200px'} /> <br />
                                <div className="animate__animated animate__bounceInUp animate__delay-2s animate__slower animate__bounce" style={{ maxWidth: '580px', padding: '5px 25px', backgroundImage: 'linear-gradient(to bottom right, green, white)', borderRadius: '10px' }} >
                                    <h3 className="animate__animated animate__zoomIn  animate__delay-4s " style={{ color: 'white', textShadow: `2px 2px 5px black, 0 0 .5em grey, 0 0 1em darkgreen` }}>{item.pasiune}</h3>
                                </div>
                            </div>
                            <div id="header" className="col-lg-8 col-md-8 col-sm-12 " style={{ maxWidth: '700px', padding: '5px 25px' }}>
                                <h1 className="animate__animated animate__bounceInRight animate__delay-2s animate__slower" style={{ color: 'white', textShadow: `2px 2px 5px black, 0 0 .5em grey, 0 0 1em darkgreen` }}>{item.descript}</h1> <br />
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-12 animate__animated animate__bounceInUp animate__delay-2s animate__slower animate__bounce" style={{ maxWidth: '600px', padding: '5px 25px', backgroundImage: 'linear-gradient(to bottom right, red, yellow)', borderRadius: '10px' }} >
                                <h2 className="animate__animated animate__zoomIn  animate__delay-4s " style={{ color: 'white', textShadow: `2px 2px 5px black, 0 0 .5em grey, 0 0 1em darkgreen` }}>{item.deviz}</h2>
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-12 mt-4 animate__animated animate__bounceInRight animate__delay-4s" style={{ maxWidth: '800px' }} >
                                <a href="#forms" style={{ fontSize: '20px' }} className="btn m-2 animate__animated animate__pulse animate__delay-4s animate__infinite">Vreau Oferta</a> <br />
                                <a href={item.phone} style={{ fontSize: '15px' }} className="btn m-2">Contacteaza-ne</a>
                            </div>


                        </div>
                    </Parallaximg>
                )
            })}

            <section id="avantaje" className="mt-md-5 mt-sm-2 pt-sm-2 pt-md-5" >
            
                <div className="text-center mt-4 pt-4">
                    <h2><b>AVANTAJELE</b> <br />Izolării cu Echipa <b>Ecoizolare.MD</b><br /> și <b>Fulgi de Celuloză</b> </h2>

                    <Beneficii />


                </div>
            </section>



            <VideoAnimation />

            
<CardService />
            

            

            {/**<BlogSectionNasii /> */}

            <section id="galerie">
                <div className="text-center mt-4 pt-4">
                    <h2>Obiectele Izolate de Echipa Ecoizolare.MD</h2>
                    <SlideGallery />
                </div>

                <GalerieVideo />
            </section>


           


            <Pret />

 <Motive />


          {/**  <FAQ />*/} 


           <FormSection />

            <section id="recenzii">
                <div className="text-center mt-4 pt-4">
                    <h2>RECENZIILE CLIENȚILOR NOȘTRI</h2>
                    <Slide />
                </div>
            </section>





            {/**<SlideAuto /> */}







           

            {data.introData.map((item) => {
                return (
                    <div>
                        <h3 className="text-center mt-5" style={{ color: '#10A31C' }}>
                            ALEGE INTELEGENT!
                        </h3>

                    </div>

                )
            })}





        </ParallaxProvider>



    )
}

export default Home;